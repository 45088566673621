import * as React from 'react'
import { graphql } from 'gatsby'
import { Container, makeStyles, Theme, createStyles, Typography, Button, Grid, Chip } from '@material-ui/core'
import IndexLayout from '../templates/IndexLayout'

interface JobTemplateProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        author: {
          name: string
          url: string
        }
      }
    }
    markdownRemark: {
      html: string
      excerpt: string
      frontmatter: {
        title: string
        description: string
        location: string
        application_link: string
        tags: string[]
      }
    }
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mdxSection: {
      textAlign: 'left',
      '& h1,h2,h3,h4,h5': {
        color: '#10296F'
      }
    },
    chip: {
      margin: '0rem 0.5rem 0rem 0.5rem'
    }
  })
)

const JobTemplate: React.SFC<JobTemplateProps> = ({ data }) => {
  const classes = useStyles()
  return (
    <IndexLayout title={data.markdownRemark.frontmatter?.title} description={"Apply for the role of " + data.markdownRemark.frontmatter?.title + " with Zeti"}>
      <div style={{ height: '10vh' }} />
      <Container maxWidth="md">
        <Typography variant="h3">{data.markdownRemark.frontmatter?.title}</Typography>
        <Typography variant="h6">
          {data.markdownRemark.frontmatter?.location}
        </Typography>
        <Grid container direction="row">
          <Grid item xs={9}>
            {(data.markdownRemark?.frontmatter?.tags ?? []).map((tag: string) => {
              return tag ? (
                <Chip className={classes.chip} label={tag} color="primary" variant="outlined" onClick={() => { }} />
              )
                :
                (<></>)
            }
            )}
          </Grid>
          <Grid item xs={3}>
            <Button href={data.markdownRemark.frontmatter?.application_link} color="primary" variant="contained" style={{float: 'right'}}>
              Apply
            </Button>
          </Grid>
        </Grid>

        <div className={classes.mdxSection} dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
      </Container>
    </IndexLayout>
  )
}

export default JobTemplate

export const query = graphql`
  query JobTemplateQuery($id: String!) {
    site {
      siteMetadata {
        title
        description
        author {
          name
          url
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      excerpt
      frontmatter {
        title
        application_link
        location
        tags
      }
    }
  }
`
